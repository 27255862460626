<template>
  <div class="post" id="kt_post">
    <div id="kt_content_container" class="container-xxl">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col mb-5">
              <label for="" class="form-label">Periode Awal</label>
              <input class="form-control" type="date" v-model="startAt" />
            </div>
            <div class="col">
              <label for="" class="form-label">Periode Akhir</label>
              <input class="form-control" type="date" v-model="endAt" />
            </div>
            <div class="col-auto" v-if="editPeriod">
              <label for="" class="form-label">Action</label>
              <div class="flex">
                <button class="btn btn-primary" @click="updateAction">
                  Update
                </button>
                <!-- <button class="btn btn-primary" @click="createPeriod" v-else>
                  Save
                </button> -->
                <button class="btn btn-secondary" @click="cancelEdit">
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="row mb-5">
                <!-- <div class="col">
                  <select
                    name=""
                    id=""
                    class="form-control"
                    v-model="periodSelected"
                  >
                    <option value="">Periode</option>
                    <option v-for="(v, k) in listPeriod" :key="k" :value="v.id">
                      {{ `${v.start_at} - ${v.end_at}` }}
                    </option>
                  </select>
                </div> -->
                <div class="col">
                  <input
                    type="file"
                    class="form form-control"
                    @change="onChange"
                  />
                  <div class="mt-3">
                    <a href="#" @click="downloadTemplate('person')">
                      Download Template Upload Personil
                    </a>
                    <!-- |
                    <a href="#" @click="downloadTemplate('kta')">   
                      Download Template Pengajuan KTA
                    </a>
                    |
                    <a href="#" @click="downloadTemplate('cetak')">
                      Download Template Cetak KTA
                    </a> -->
                  </div>
                </div>
                <!-- <div class="col-auto">
                  <div class="form-check mt-3">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="isAjukan"
                      id="pengajuan-kta"
                    />
                    <label class="form-check-label" for="pengajuan-kta">
                      Ajukan
                    </label>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="form-check mt-3">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="isApprove"
                      id="approval"
                    />
                    <label class="form-check-label" for="approval">
                      Approval
                    </label>
                  </div>
                </div> -->
                <div class="col-auto text-right">
                  <button class="btn btn-primary" @click="doUpload">
                    Upload
                  </button>
                  <button
                    v-if="jsondata"
                    class="btn btn-success"
                    @click="doRefresh"
                  >
                    Refresh
                  </button>
                  <!-- <button class="btn btn-secondary" @click="$router.back()">
                    Kembali
                  </button> -->
                  <button
                    class="btn btn-sm"
                    @click="selfMapping = !selfMapping"
                  >
                    Mapping data
                  </button>
                </div>
              </div>
              <div v-if="isAjukan" class="alert alert-danger" role="alert">
                Data yang di upload akan langsung dibuat pengajuan KTA
              </div>
              <div v-if="isApprove" class="alert alert-danger" role="alert">
                Data yang di upload akan langsung dibuat sudah di approve
              </div>
              <hr />
            </div>
          </div>

          <div class="progress mb-5" v-if="currentProgres > 0">
            <div
              class="progress-bar"
              role="progressbar"
              :style="`width: ${currentProgres}%`"
              :aria-valuenow="currentProgres"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ currentProgres }} %
            </div>
          </div>

          <div v-if="selfMapping">
            <div class="row mb-3">
              <div class="col">
                <input
                  accept="application/JSON"
                  @input="handleMapFile"
                  type="file"
                  class="form-control form-control-sm"
                />
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-secondary btn-sm"
                  @click="loadMappingData"
                >
                  load
                </button>
                <button class="btn btn-secondary btn-sm" @click="downloadMap">
                  download
                </button>
              </div>
            </div>
            <div class="row mb-3" v-for="(v, k) in mapper" :key="k">
              <div class="col">
                <select
                  v-model="mapper[k].source"
                  name=""
                  id=""
                  class="form-control form-control-sm"
                >
                  <option value="">Select Form Field</option>
                  <option
                    :value="field"
                    v-for="(field, formKey) in personFormFields"
                    :key="formKey"
                  >
                    {{ field }}
                  </option>
                </select>
              </div>
              <div class="col">
                <select
                  v-model="mapper[k].from"
                  name=""
                  id=""
                  class="form-control form-control-sm"
                >
                  <option value="">Select Source</option>
                  <option
                    :value="header"
                    v-for="(header, headerKey) in fileHeaders"
                    :key="headerKey"
                  >
                    {{ header }}
                  </option>
                </select>
                <!-- <input type="text" class="form-control form-control-sm" /> -->
              </div>
              <div class="col-auto">
                <button
                  v-if="k < mapper.length - 1"
                  class="btn btn-sm btn-danger"
                  @click="removeMap(k)"
                >
                  delete
                </button>
                <button
                  v-if="k == mapper.length - 1"
                  class="btn btn-sm btn-success"
                  @click="addMapp"
                >
                  add
                </button>
              </div>
            </div>
          </div>

          <vue-excel-editor
            v-if="jsondata && currentProgres == 0"
            v-model="jsondata"
            filter-row
          />

          <div v-if="logMessage.length > 0">
            <pre>{{ logMessage }}</pre>
          </div>
        </div>
      </div>
    </div>
    <div id="kt_content_container" class="container-xxl mt-10">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <BaseTable
            :columns="columns"
            :service="'period'"
            :createButton="false"
            ref="table"
            @actionButton="handleAction"
            @selectedRows="handleSelected"
          >
            <template #action-button="{ data }">
              <div>
                <button
                  class="btn btn-success btn-sm"
                  @click="handleAction({ type: 'detail', data: data.value })"
                >
                  Lihat Data
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  @click="handleAction({ type: 'view', data: data.value })"
                >
                  Edit
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  @click="handleAction({ type: 'delete', data: data.value })"
                >
                  Delete
                </button>
              </div>
            </template>
          </BaseTable>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { read, utils } from "xlsx";
import Service from "@/services/base.service";
import BaseTable from "/src/components/BaseTable.vue";

export default {
  components: {
    BaseTable,
    // VueExcelEditor,
  },
  data() {
    return {
      file: null,
      jsondata: null,
      logMessage: [],
      isAjukan: false,
      isApprove: false,
      startAt: null,
      endAt: null,
      currentPeriod: {},
      periodSelected: null,
      listPeriod: [],
      columns: [
        { field: "id", title: "ID", isUnique: true, type: "number" },
        { field: "start_at", title: "Mulai Periode" },
        { field: "end_at", title: "Akhir Periode" },
        { field: "counter", title: "Jml Data" },
        { field: "actions", title: "Actions" },
      ],
      selfMapping: false,
      fileHeaders: [],
      personFormFields: [
        "nama_lengkap",
        "nip_nrp",
        "tinggi",
        "berat",
        "rambut",
        "mata",
        "gol_darah",
        "tempat_lahir",
        "tanggal_lahir",
        "agama",
        "alamat",
        "kontak_darurat",
        "nama_pasangan",
        "nama_ortu",
        "tempat_kawin",
        "no_surat_kawin",
        "dimension_name",
        "position_name",
        "rank_name",
        "unity_name",
        "honors",
        // "status",
      ],
      mapperRaw: [],
      mapper: [{ source: "", from: "" }],
      onProgresUpload: false,
      currentProgres: 0,
    };
  },
  computed: {
    editPeriod() {
      return this.currentPeriod && this.currentPeriod.id;
    },
  },
  watch: {
    selfMapping(value) {
      if (value) {
        this.setDefaultMap();
      }
    },
  },
  mounted() {
    // this.init();
  },
  methods: {
    async init() {
      const BaseService = new Service("period");
      const { data } = await BaseService.getListData({ size: 99999 });
      this.listPeriod = data;
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = read(bstr, { type: "binary" });
          // /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // /* Convert array of arrays */
          const data = utils.sheet_to_json(ws, { header: 1 });
          this.fileHeaders = data[0];
          this.jsondata = data;
        };
        reader.readAsBinaryString(this.file);
      }
      // console.log(event);
    },
    async createPerson(row) {
      const personService = new Service("person");
      const cardApplicationService = new Service("card-application");

      const param = {
        nip_nrp: row[5],
        nama_lengkap: row[4],
        tinggi: row[12] ? parseInt(row[12].replace(/[^.\d]/g, "")) : 0,
        berat: row[13] ? parseInt(row[13].replace(/[^.\d]/g, "")) : 0,
        rambut: row[14] ?? null,
        mata: row[15] ?? null,
        gol_darah: row[11] ? row[11].trim() : null,
        tempat_lahir: row[19] ?? null,
        tanggal_lahir: row[20] ?? null,
        agama: row[21] ?? null,
        alamat: row[17] ?? null,
        kontak_darurat: row[16] ?? null,
        nama_pasangan: row[18] ?? null,
        nama_ortu: row[22] ?? null,
        tempat_kawin: row[23] ?? null,
        no_surat_kawin: row[24] ?? null,
        dimension_name: row[10] ?? null,
        position_name: row[8] ?? null,
        rank_name: row[6] ?? null,
        unity_name: row[9] ?? null,
        honors: row[25] ? [row[25]] : null,
      };
      try {
        const person = await personService.createData(param);
        if (person.data) {
          this.logMessage.push(
            `create person: #${person.data.id} => ${person.data.nama_lengkap}`
          );

          // KTA NUMBER
          if (person && row[3]) {
            const cardApplicationParam = {
              kta_number: row[3],
              person_id: person.data.id,
              card_type_name: row[2],
            };

            const cardApplication = await cardApplicationService.createData(
              cardApplicationParam
            );

            this.logMessage.push(
              `create card application person: #${cardApplication.data.id} #${person.data.id} => ${cardApplication.data.kta_number}`
            );

            if (cardApplication && row[1]) {
              const cardApprovalService = new Service("approval/approve");
              const cardService = new Service("card");
              // APPROVE  CARD APPLICATION
              // const cardApproval = await cardApprovalService.createData({
              //   cardApplicationId: cardApplication.data.id,
              //   status: "TTE",
              // });
              // console.log(cardApproval);
              // const card = await cardService.createData({
              //   cardApplicationId: cardApplication.data.id,
              //   serial_number: row[1],
              // });
              // console.log(card);
              const mergeRequest = [
                cardApprovalService.createData({
                  cardApplicationId: cardApplication.data.id,
                  status: "TTE",
                }),
                cardService.createData({
                  cardApplicationId: cardApplication.data.id,
                  serial_number: row[1],
                }),
              ];
              const [approval, card] = await Promise.all(mergeRequest);
              console.log(approval, card);
              this.logMessage.push(
                `approve card application: #${approval.data.id} #${person.data.id}`
              );
              this.logMessage.push(
                `create card : #${card.data.id} #${card.data.serial_number}`
              );
            }
          }
        } else {
          this.logMessage.push(
            `create person: #${person.message} => ${param.nip_nrp}`
          );
        }
      } catch (error) {
        this.logMessage.push(error);
        // console.log(error);
      }
    },
    /*
    doUpload() {
      const mapper = toRaw(this.mapper);
      const header = { ...toRaw(this.fileHeaders) };
      // mapper.map((r) => {
      //   const m = r;
      //   m.from = Object.keys(header).find((key) => header[key] === m.from);
      //   return m;
      // });

      const rawData = toRaw(this.jsondata);
      for (const row of rawData.slice(1)) {
        const params = {};
        for (const m of mapper) {
          const k = Object.keys(header).filter((key) => {
            // console.log(key, header[key], m.source);
            return header[key] == m.source;
          });
          // console.log("key", k, m.source, m.from);
          const val = row[parseInt(k)];
          params[m.source] = val ?? null;
        }
        console.log(params);
        // mapper.forEach((m) => {
        //   console.log("m", m.source);
        //   const k = Object.keys(header).filter((key) => {
        //     console.log(key, header[key], m.source);
        //     return header[key] == m.source;
        //   });
        //   console.log("key", k, m.source, m.from);
        //   const val = row[parseInt(k)];
        //   params[m.source] = val ?? null;
        // });
        // console.log(params);
        // const param = {
        //   nip_nrp: row[5],
        //   nama_lengkap: row[4],
        //   tinggi: row[12] ? parseInt(row[12].replace(/[^.\d]/g, "")) : 0,
        //   berat: row[13] ? parseInt(row[13].replace(/[^.\d]/g, "")) : 0,
        //   rambut: row[14] ?? null,
        //   mata: row[15] ?? null,
        //   gol_darah: row[11] ? row[11].trim() : null,
        //   tempat_lahir: row[19] ?? null,
        //   tanggal_lahir: row[20] ?? null,
        //   agama: row[21] ?? null,
        //   alamat: row[17] ?? null,
        //   kontak_darurat: row[16] ?? null,
        //   nama_pasangan: row[18] ?? null,
        //   nama_ortu: row[22] ?? null,
        //   tempat_kawin: row[23] ?? null,
        //   no_surat_kawin: row[24] ?? null,
        //   dimension_name: row[10] ?? null,
        //   position_name: row[8] ?? null,
        //   rank_name: row[6] ?? null,
        //   unity_name: row[9] ?? null,
        //   honors: row[25] ? [row[25]] : null,
        //   status: "UPLOADED",
        //   period_id: period.data.id,
        // };
      }
    },
    */
    async doUpload() {
      const rawData = toRaw(this.jsondata);

      if (!this.startAt || !this.endAt) {
        this.$swal.fire({
          icon: "error",
          title: "Silahkan input tanggal periode",
        });
        return;
      }

      if (!rawData) {
        this.$swal.fire({
          icon: "error",
          title: "Tidak ada data yang di upload",
        });
        return;
      }

      this.onProgresUpload = true;
      // this.createPerson(rawData[1]);
      // let count = 0;

      const personService = new Service("person");
      const cardApplicationService = new Service("card-application");

      const periodService = new Service("period");
      const period = await periodService.createData({
        start_at: this.startAt,
        end_at: this.endAt,
      });
      // const period = {
      //   data: { id: this.periodSelected },
      // };

      let counterData = 0;
      let successUpload = 0;
      const totalData = rawData.length - 1;

      for (const row of rawData.slice(1)) {
        // ++count;
        // this.createPerson(row);
        // const param = {
        //   nip_nrp: row[5],
        //   nama_lengkap: row[4],
        //   tinggi: row[12] ? parseInt(row[12].replace(/[^.\d]/g, "")) : 0,
        //   berat: row[13] ? parseInt(row[13].replace(/[^.\d]/g, "")) : 0,
        //   rambut: row[14] ?? null,
        //   mata: row[15] ?? null,
        //   gol_darah: row[11] ? row[11].trim() : null,
        //   tempat_lahir: row[19] ?? null,
        //   tanggal_lahir: row[20] ?? null,
        //   agama: row[21] ?? null,
        //   alamat: row[17] ?? null,
        //   kontak_darurat: row[16] ?? null,
        //   nama_pasangan: row[18] ?? null,
        //   nama_ortu: row[22] ?? null,
        //   tempat_kawin: row[23] ?? null,
        //   no_surat_kawin: row[24] ?? null,
        //   dimension_name: row[10] ?? null,
        //   position_name: row[8] ?? null,
        //   rank_name: row[6] ?? null,
        //   unity_name: row[9] ?? null,
        //   honors: row[25] ? [row[25]] : null,
        //   status: "UPLOADED",
        //   period_id: period.data.id,
        // };
        const param = {
          nama_lengkap: row[1],
          nip_nrp: row[2],
          rank_name: row[3] ?? null,
          korps_name: row[4] ?? null,
          position_name: row[5] ?? null,
          unity_name: row[6] ?? null,
          dimension_name: row[7] ?? null,
          gol_darah: row[8] ? row[8].trim() : null,
          tinggi: row[9] ? parseInt(row[9].replace(/[^.\d]/g, "")) : 0,
          berat: row[10] ? parseInt(row[10].replace(/[^.\d]/g, "")) : 0,
          rambut: row[11] ?? null,
          mata: row[12] ?? null,
          kontak_darurat: row[13] ?? null,
          alamat: row[14] ?? null,
          nama_pasangan: row[15] ?? null,
          tempat_lahir: row[16] ?? null,
          tanggal_lahir: row[17] ?? null,
          agama: row[18] ?? null,
          nama_ortu: row[19] ?? null,
          tempat_kawin: row[20] ?? null,
          no_surat_kawin: row[21] ?? null,
          honors: row[22] ? row[22].split(",") : null,
          satkerid: row[23] ? row[23] : null,
          status: "UPLOADED",
          period_id: period.data.id,
        };
        ++counterData;

        try {
          const person = await personService.createData(param);

          if (person.data) {
            this.logMessage.push(
              `create person: #${person.data.id} => ${person.data.nama_lengkap}`
            );
            ++successUpload;
            // KTA NUMBER
            if (person && row[3] && this.isAjukan) {
              console.log(this.isAjukan);

              const cardApplicationParam = {
                kta_number: row[3],
                person_id: person.data.id,
                card_type_name: row[2],
              };

              const cardApplication = await cardApplicationService.createData(
                cardApplicationParam
              );

              this.logMessage.push(
                `create card application person: #${cardApplication.data.id} #${person.data.id} => ${cardApplication.data.kta_number}`
              );

              if (cardApplication && row[1] && this.isApprove) {
                const cardApprovalService = new Service("approval/approve");
                const cardService = new Service("card");
                // APPROVE  CARD APPLICATION
                // const cardApproval = await cardApprovalService.createData({
                //   cardApplicationId: cardApplication.data.id,
                //   status: "TTE",
                // });
                // console.log(cardApproval);
                // const card = await cardService.createData({
                //   cardApplicationId: cardApplication.data.id,
                //   serial_number: row[1],
                // });
                // console.log(card);
                const mergeRequest = [
                  cardApprovalService.createData({
                    cardApplicationId: cardApplication.data.id,
                    status: "TTE",
                  }),
                  cardService.createData({
                    cardApplicationId: cardApplication.data.id,
                    serial_number: row[1],
                  }),
                ];
                const [approval, card] = await Promise.all(mergeRequest);
                console.log(approval, card);
                this.logMessage.push(
                  `approve card application: #${approval.data.id} #${person.data.id}`
                );
                this.logMessage.push(
                  `create card : #${card.data.id} #${card.data.serial_number}`
                );
              }
            }
          } else {
            this.logMessage.push(
              `create person: #${person.message} => ${param.nip_nrp}`
            );
          }
        } catch (error) {
          this.logMessage.push(error);
          // console.log(error);
        }
        this.currentProgres = (counterData / totalData) * 100;
      }

      if (successUpload > 0) {
        await periodService.updateData(period.data.id, {
          counter: period.data.counter + successUpload,
        });
        this.$refs.table.reload();
        this.$swal.fire("data berhasil di upload");
      }

      // rawData.forEach((row, index) => {
      //   if (index >= 1) {
      //     this.createPerson(row);
      //   }
      // });
    },
    doRefresh() {
      this.isAjukan = false;
      this.isApprove = false;
      this.logMessage = [];
      this.jsondata = null;
      this.currentProgres = 0;
    },
    handleAction(value) {
      switch (value.type) {
        case "view":
          this.editConfirm(value.data);
          break;
        case "delete":
          this.deleteConfim(value.data.id);
          break;
        case "detail":
          this.$router.push({
            name: "verification-verify",
            query: { period: value.data.id },
          });
          console.log("detail");
          break;
        default:
          break;
      }
    },
    editConfirm(data) {
      const { start_at, end_at } = data;
      this.currentPeriod = data;
      this.startAt = start_at;
      this.endAt = end_at;
    },
    cancelEdit() {
      this.currentPeriod = {};
      this.startAt = this.endAt = null;
    },
    deleteConfim(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteAction(id);
          }
        });
    },
    async deleteAction(id) {
      try {
        const BaseService = new Service("period");
        await BaseService.deleteData(id);
        this.$refs.table.reload();
      } catch (error) {
        console.log(error);
      }
    },
    createPeriod() {
      if (!this.startAt || !this.endAt) {
        this.$swal.fire("Input tidak boleh kosong");
        return;
      }
      this.createAction();
    },
    async createAction() {
      try {
        const BaseService = new Service("period");
        const { message } = await BaseService.createData({
          start_at: this.startAt,
          end_at: this.endAt,
        });
        this.$refs.table.reload();
        this.$swal.fire(message);
        this.resetFormPeriod();
      } catch (error) {
        console.log(error);
      }
    },
    async updateAction() {
      try {
        const BaseService = new Service("period");
        const { message } = await BaseService.updateData(
          this.currentPeriod.id,
          {
            start_at: this.startAt,
            end_at: this.endAt,
          }
        );
        this.$refs.table.reload();
        this.$swal.fire(message);
        this.resetFormPeriod();
      } catch (error) {
        console.log(error);
      }
    },
    resetFormPeriod() {
      this.currentPeriod = {};
      this.startAt = this.endAt = null;
    },
    downloadTemplate() {
      const fileUrl = `${window.location.protocol}//${window.location.host}/sample-upload-data-personil.xlsx`;
      window.open(fileUrl);
    },
    addMapp() {
      this.mapper.push({ source: "", from: "" });
    },
    removeMap(index) {
      this.mapper.splice(index, 1);
    },
    handleMapFile(event) {
      const file = event.target.files ? event.target.files[0] : null;
      const reader = new FileReader();
      reader.onload = (e) => {
        const str = e.target.result;
        this.mapperRaw = toRaw(JSON.parse(str));
      };
      // console.log(file, this.mapperRaw);
      reader.readAsText(file);
    },
    loadMappingData() {
      if (this.mapperRaw) {
        this.mapper = this.mapperRaw;
        this.fileHeaders = this.mapper.map((r) => r.from);
        console.log(this.mapper);
      }
    },
    downloadMap() {
      const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(this.mapper)
      )}`;
      const dlAnchorElem = document.createElement("a");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", `_datamap.json`);
      dlAnchorElem.click();
      dlAnchorElem.remove();
    },
    setDefaultMap() {
      this.mapper = [];
      this.personFormFields.forEach((m) => {
        this.mapper.push({ source: m, from: "" });
      });
    },
  },
};
</script>
